import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ClassifierRepository = RepositoryFactory.get('classifier');

export const transformDataFieldArray = (arr) =>
  arr.map((m) => {
    const n = {};
    n.Code = m.Code;
    n.Id = m.Id;
    n.Name = m.Name[0].Value;
    return n;
  });

export default {
  namespaced: true,
  state: {
    // common data
    brands: [],
    storageTemperatures: [],
    storageRequirements: [],
    usageMethods: [],
    tags: [],
  },
  mutations: {
    SET_BRANDS: (state, value) => {
      state.brands = transformDataFieldArray(value);
    },
    SET_STORAGE_TEMPERATURES: (state, value) => {
      state.storageTemperatures = transformDataFieldArray(value);
    },
    SET_STORAGE_REQUIREMENTS: (state, value) => {
      state.storageRequirements = transformDataFieldArray(value);
    },
    SET_USAGE_METHODS: (state, value) => {
      state.usageMethods = transformDataFieldArray(value);
    },
    SET_TAGS: (state, value) => {
      state.tags = transformDataFieldArray(value);
    },
  },
  getters: {},
  actions: {
    async fetchCommonClassifiers({ dispatch }, { forceUpdate } = {}) {
      dispatch('fetchBrands', forceUpdate);
      dispatch('fetchStorageTemperatures', forceUpdate);
      dispatch('fetchStorageRequirements', forceUpdate);
      dispatch('fetchUsageMethods', forceUpdate);
      await dispatch('fetchTags', forceUpdate); // await only required for loading product
    },
    async fetchBrands({ state, commit }, { forceUpdate } = {}) {
      if (!(state.brands.length === 0 || forceUpdate)) return;
      try {
        const brands = (await ClassifierRepository.search('ART_BRNAME')).data;
        commit('SET_BRANDS', brands);
      } catch (error) {
        console.error(
          `Failed to retrieve results (fetchBrands): ${error.message}`,
        );
      }
    },
    async fetchStorageTemperatures({ state, commit }, { forceUpdate } = {}) {
      if (!(state.storageTemperatures.length === 0 || forceUpdate)) return;
      try {
        const storageTemperatures = (
          await ClassifierRepository.search('ARTTEMP')
        ).data;
        commit('SET_STORAGE_TEMPERATURES', storageTemperatures);
      } catch (error) {
        console.error(
          `Failed to retrieve results (fetchStorageTemperatures): ${error.message}`,
        );
      }
    },
    async fetchStorageRequirements({ state, commit }, { forceUpdate } = {}) {
      if (!(state.storageRequirements.length === 0 || forceUpdate)) return;
      try {
        const storageRequirements = (
          await ClassifierRepository.search('HOIUSTUS')
        ).data;
        commit('SET_STORAGE_REQUIREMENTS', storageRequirements);
      } catch (error) {
        console.error(
          `Failed to retrieve results (fetchStorageRequirements): ${error.message}`,
        );
      }
    },
    async fetchUsageMethods({ state, commit }, { forceUpdate } = {}) {
      if (!(state.usageMethods.length === 0 || forceUpdate)) return;
      try {
        const usageMethods = (await ClassifierRepository.search('MANUSTVIIS'))
          .data;
        commit('SET_USAGE_METHODS', usageMethods);
      } catch (error) {
        console.error(
          `Failed to retrieve results (fetchUsageMethods): ${error.message}`,
        );
      }
    },
    async fetchTags({ commit }) {
      // Always fetch this resource because user can set custom tag dynamicaly in <v-select> control.
      // And after submitin form, Tag is created on server.
      try {
        const tags = (await ClassifierRepository.search('MARKSONA')).data;
        commit('SET_TAGS', tags);
      } catch (error) {
        console.error(
          `Failed to retrieve results (fetchTags): ${error.message}`,
        );
      }
    },
  },
};
