import store from '../store/store.js';

export default async (to, from, next) => {
  await store.dispatch('autoLogin');
  if (to.name !== 'page-login') {
    next();

    if (store.getters.isLogedIn) {
      next();
    } else {
      next({
        path: '/pages/login',
        replace: true,
      });
    }
  } else if (store.getters.isLogedIn) {
    next({
      path: '/',
      replace: true,
    });
  }
  next();
};
