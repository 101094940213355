import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, min } from 'vee-validate/dist/rules';

// Add a rule.
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'This field must have no less than {length} characters',
});

extend('tel', {
  validate: (value) => value.match(/^[0-9]*$/u),
  message: 'Telephone Number must not contain any letter or special character',
});
extend('email', email);

extend('iso', {
  validate: (value) =>
    value.match(/^([\d][\d]\.){1,3}([\d][\d])$/) && value.length >= 8,
  message:
    'Iso Code should follow following pattern ex-09.10.23.10 and at least 8 characters',
});
extend('decimal', {
  validate: (value) => value.match(/^\d+(\.\d{1,2})?$/),
  message: 'Price should not exceed two decimal points',
});

extend('no_whitespace', {
  validate: (value) => value.match(/^\S*$/u),
  message: 'Password must not contain whitespace',
});
extend('min_tel', {
  validate: (value) => value.length > 6,
  message: 'Contact number must contain at least 7 numbers.',
});
extend('max_tel', {
  validate: (value) => value.length < 17,
  message: 'Contact number allowed only 16 numbers ',
});
extend('contactNumber', {
  validate: (value) => value.match(/^[+]{0,1}[0-9]*$/g),
  message: 'Enter a valid contact number ',
});

extend('g2fa', {
  validate: (value) => value.length === 6,
  message: 'Login code must include 6 numbers ',
});

extend('age_restriction', {
  validate: (value) => value >= 0 && value <= 130,
  message: 'Age Restriction must be between 0 to 130 & Input must be numeric',
});

extend('one_uppercase', {
  validate: (value) => value.match(/[a-z]/),
  message: 'Password must contain at least one Lowercase character',
});
extend('one_lowercase', {
  validate: (value) => value.match(/[A-Z]/),
  message: 'Password must contain at least one Uppercase character',
});
extend('selected', {
  ...required,
  message: 'Select at least one item from dropdown',
});

extend('one_number', {
  validate: (value) => value.match(/[0-9]/),
  message: 'Password must contain at least one numeral',
});

extend('one_special', {
  validate: (value) => value.match(/[$&+,:;=?@#|'<>.^*()%!-]/),
  message: 'Password must contain at least one special character',
});

extend('same_password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation should be matched',
});

// Register it globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
