import Repository from '../axios';

export default {
  get() {
    return Repository.get('/productrecall');
  },
  getOne(id) {
    return Repository.get(`/productrecall/${id}`);
  },
  create(payload) {
    return Repository.post('productrecall', payload);
  },
  delete(id) {
    return Repository.delete(`/productrecall/${id}`);
  },
  addPharmacyToRecall(id, payload) {
    return Repository.post(`productrecall/${id}/pharmacy`, payload);
  },
  deletePharmacyFromRecall(recallId, pharmacyId) {
    return Repository.delete(
      `/productrecall/${recallId}/pharmacy/${pharmacyId}`,
    );
  },
  addProductToRecall(id, payload) {
    return Repository.post(`productrecall/${id}/product`, payload);
  },
  deleteProductFromRecall(recallId, relationId) {
    return Repository.delete(
      `/productrecall/${recallId}/product/${relationId}`,
    );
  },
  confirmRecall(id) {
    return Repository.put(`/productrecall/${id}`);
  },
};
