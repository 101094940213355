import Repository from '../axios';
import { getUrlParamsFromObj } from '@/utils/string';

const resource = '/price/manufacturerpricechange';

export default {
  get(conf, params) {
    const config = { ...(conf || {}) };
    const urlParamsStr = getUrlParamsFromObj(config);

    return Repository.get(
      `${resource}${urlParamsStr.length > 0 ? `?${urlParamsStr}` : ''}`,
      params,
    );
  },
  // search (term) {
  //   return Repository.get(`/classifier/${term}`)
  // },
  create(payload) {
    return Repository.post(`${resource}`, payload);
  },
  confirmAll() {
    return Repository.put(`${resource}`);
  },
  singleConfirm(id) {
    return Repository.put(`${resource}/${id}`);
  },
  deleteAll() {
    return Repository.delete(`${resource}`);
  },
  singleDelete(id) {
    return Repository.delete(`${resource}/${id}`);
  },
  // addTranslation(id, payload) {
  //   return Repository.post(`/classifier/${id}/translation`, payload)
  // }
};
