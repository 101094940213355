import UserRepository from './userRepository';
import NodeRepository from './nodeRepository';
import TranslationRepository from './translationRepository';
import ProductRepository from './productRepository';
import ClassifierRepository from './classifierRepository';
import PriceChangeRepository from './priceChangeRepository';
import ProductRecallRepository from './productRecallRepository';
import PharmacyRepository from './pharmacyRepository';
import SupplierRepository from './supplierRepository';
import DocumentRepository from './documentRepository';
import ProductRelationRepository from './productRelationRepository';
import RimipointRpository from './rimipointRpository';
import GiftCartRepository from './giftcardRepository';
import MeddecRpository from './meddecRpository';

const repositories = {
  user: UserRepository,
  node: NodeRepository,
  product: ProductRepository,
  translation: TranslationRepository,
  classifier: ClassifierRepository,
  price: PriceChangeRepository,
  recall: ProductRecallRepository,
  pharmacy: PharmacyRepository,
  supplier: SupplierRepository,
  document: DocumentRepository,
  productRelation: ProductRelationRepository,
  rimipoint: RimipointRpository,
  giftcard: GiftCartRepository,
  meddec: MeddecRpository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
