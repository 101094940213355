/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';

// Vuex Store
import store from './store/store';

import interceptorsSetup from '@/middleware/interceptors';
import moment from 'moment';
import 'vue-tree-halower/dist/halower-tree.min.css'; // you can customize the style of the tree
import VTree from 'vue-tree-halower';

Vue.use(VTree);

// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax

Vue.use(Vuesax);
Vue.use(interceptorsSetup);

import VueFilterDateFormat from 'vue-filter-date-format';
Vue.use(VueFilterDateFormat);

import './validation.init';

Vue.mixin({
  methods: {
    __translations: function(Name) {
      if (!Name) {
        return '';
      }
      const name = Name.find((n) => {
        return n.Language === store.getters.currentLanguage;
      });
      if (name) {
        return name.Value;
      } else {
        return Name.find((n) => {
          return n.Language === 'EST';
        }).Value;
      }
    },
    __dateFormatter: function(Date) {
      if (Date) {
        const newDate = moment(Date).format('DD.MM.YYYY');
        return newDate;
      } else {
        return '-';
      }
    },
    __dateTimeFormatter: function(Date) {
      if (Date) {
        const newDate = moment(Date).format('DD.MM.YYYY - HH:MM ');
        return newDate;
      } else {
        return 'va';
      }
    },
    __priceFormatter: function(Value) {
      const val = (Value / 1).toFixed(2).replace(' ', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
});

// axios
import axios from './axios.js';
Vue.prototype.$http = axios;

// Filters
import './filters/filters.js';

// Theme Configurations
import '../themeConfig.js';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// i18n
// import i18n from './i18n/i18n'

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';
Vue.use(VueHammer);

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Feather font icon
require('./assets/css/iconfont.css');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App),
}).$mount('#app');
