import Repository from '../axios';

export default {
  get() {
    return Repository.get('/productrelation');
  },
  delete(id) {
    return Repository.delete(`/productrelation/${id}`);
  },
  create(payload, type) {
    return Repository.post(`/productrelation/${type}`, payload);
  },
};
