import Repository from '../axios';

export default {
  add(id, payload) {
    return Repository.post(`/producttree/${id}/translation`, payload);
  },
  delete(id) {
    return Repository.delete(`/translation/${id}`);
  },
  get(id) {
    return Repository.get(`/translation/${id}`);
  },
  update(id, payload) {
    return Repository.put(`/translation/${id}`, payload);
  },
  productDescriptionAddTranslation(productId, descriptionId, payload) {
    return Repository.post(
      `/product/${productId}/description/${descriptionId}/translation`,
      payload,
    );
  },
  productReasonForUseAddTranslation(productId, reasonId, payload) {
    return Repository.post(
      `/product/${productId}/reasonforuse/${reasonId}/translation`,
      payload,
    );
  },
  productUsageInstructionsAddTranslation(
    productId,
    usageInstructionId,
    payload,
  ) {
    return Repository.post(
      `/product/${productId}/usageinstruction/${usageInstructionId}/translation`,
      payload,
    );
  },
  productTagAddTranslation(productId, tagId, payload) {
    return Repository.post(
      `/product/${productId}/tag/${tagId}/translation`,
      payload,
    );
  },
  productNameAddTranslation(productId, payload) {
    return Repository.post(`/product/${productId}/name/translation`, payload);
  },
};
