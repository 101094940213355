// axios
import axios from 'axios';

const baseURL =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:8090/api' : '/api'; // http://localhost:8090/api
// const baseURL = 'https://physicalweb-staging.smartfarma.ee/api'

export default axios.create({
  baseURL,
  // You can add your headers here
});
