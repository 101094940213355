/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';
import AutoLogin from '@/middleware/autoLogin.js';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      beforeEnter: AutoLogin,
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
        },
        {
          path: '/node-manage',
          name: 'node-manage',
          component: () =>
            import('@/views/pages/node-manage/NodeManageGenaral.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Group Manage', active: true },
            ],
            pageTitle: 'Group Manage',
            rule: 'editor',
          },
        },
        {
          path: '/products',
          name: 'products',
          component: () =>
            import('@/views/pages/product-manage/ProductTest.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Products' },
              { title: 'Product Items', active: true },
            ],
            pageTitle: 'Manage Products',
            rule: 'editor',
          },
        },
        {
          path: '/products/:id',
          name: 'products-view',
          component: () =>
            import('@/views/pages/product-manage/ProductDetails.vue'),
          meta: {
            parent: 'products',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Products' },
              { title: 'Product Items', url: '/products' },
              { title: 'Details', active: true },
            ],
            pageTitle: 'Manage Products',
            rule: 'editor',
          },
        },
        {
          path: '/classifiers',
          name: 'classifiers-type',
          component: () =>
            import('@/views/pages/classifiers-manage/ClassifierList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Classifier Types', active: true },
            ],
            pageTitle: 'Classifiers',
            rule: 'editor',
          },
        },
        {
          path: '/classifiers/:type',
          name: 'classifiers-list',
          component: () =>
            import('@/views/pages/classifiers-manage/ClassifierByType.vue'),
          meta: {
            parent: 'classifiers-type',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Classifier Types', url: '/classifiers' },
              { title: 'Classifiers', active: true },
            ],
            pageTitle: 'Classifiers',
            rule: 'editor',
          },
        },
        // {
        //   path: '/price-changes',
        //   name: 'price-changes',
        //   component: () =>
        //     import('@/views/pages/price-change-manage/PriceChangesGenaral.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Manufacturer Price Change', active: true },
        //     ],
        //     pageTitle: 'Price Changes',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/product-recalls',
        //   name: 'product-recalls',
        //   component: () =>
        //     import('@/views/pages/peoduct-recall/ProductRecallsGeneral.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Product Recalls', active: true },
        //     ],
        //     pageTitle: 'Product Recalls',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/product-recalls-details/:id',
        //   name: 'product-recalls-details',
        //   component: () =>
        //     import('@/views/pages/peoduct-recall/ProductRecallDetails.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Product Recalls Details', active: true },
        //     ],
        //     pageTitle: 'Product Recalls Details',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/document-approval',
        //   name: 'document-approval',
        //   component: () =>
        //     import(
        //       '@/views/pages/document-approval/DocumentApprovalGeneral.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Documents', active: true },
        //     ],
        //     pageTitle: 'Documents Approval',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/meddec',
        //   name: 'meddec',
        //   component: () => import('@/views/pages/meddec/MeddecGeneral.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Other' },
        //       { title: 'MEDDEC', active: true },
        //     ],
        //     pageTitle: 'MEDDEC',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/rimi-points',
        //   name: 'rimi-points',
        //   component: () => import('@/views/pages/rimi-points/RimiGeneral.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Other' },
        //       { title: 'Rimi Points', active: true },
        //     ],
        //     pageTitle: 'Rimi Points',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/gift-cards',
        //   name: 'gift-cards',
        //   component: () => import('@/views/pages/gift-cards/GiftGeneral.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Other' },
        //       { title: 'Gift Cards', active: true },
        //     ],
        //     pageTitle: 'Gift Cards',
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/product-relations',
        //   name: 'product-relations',
        //   component: () =>
        //     import(
        //       '@/views/pages/product-relations/ProductRelationsGeneral.vue'
        //     ),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Product Relations', active: true },
        //     ],
        //     pageTitle: 'Product Relations',
        //     rule: 'editor',
        //   },
        // },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      beforeEnter: AutoLogin,
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue'),
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
