import Repository from '../axios';

export default {
  get() {
    return Repository.get(`/meddec`);
  },
  delete(id) {
    return Repository.delete(`/meddec/${id}`);
  },
  getOne(id) {
    return Repository.get(`/meddec/${id}`);
  },
  create(payload) {
    return Repository.post('/meddec', payload);
  },
  update(id, payload) {
    return Repository.put(`/meddec/${id}`, payload);
  },
};
