import Repository from '../axios';

export default {
  get() {
    return Repository.get('/documentapproval');
  },
  grantAndRevoke(id) {
    return Repository.put(`/documentapproval/${id}`);
  },
};
