import { RepositoryFactory } from '@/repository/RepositoryFactory';
const UserRepository = RepositoryFactory.get('user');
import VueJwtDecode from 'vue-jwt-decode';

import Cookies from 'js-cookie';
import axios from '@/axios';

export default {
  state: {
    current_user_data: null,
    current_user_permission: null,
    current_user_language: null,
    current_user_language_array: [],
  },
  mutations: {
    CLEAR_CURRENT_USER: (state) => {
      state.current_user_data = undefined;
    },
    SET_CURRENT_USER: (state, value) => {
      state.current_user_data = value.user;
      state.current_user_language = value.defLang;
      state.current_user_language_array = value.trLang;
      Cookies.set('current_user_token', value.token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${value.token}`;
    },
    SET_LANGUAGE: (state, value) => {
      state.current_user_language = value;
    },
  },
  getters: {
    isLogedIn: (state) => state.current_user_data !== null,
    currentUser: (state) => state.current_user_data,
    currentLanguage: (state) => state.current_user_language,
    current_user_language_array: (state) => state.current_user_language_array,
  },
  actions: {
    setCurrentUser: ({ commit }, payload) => {
      commit('SET_CURRENT_USER', payload);
    },
    async setLanguage({ commit }, payload) {
      commit('SET_LANGUAGE', payload);
    },
    async autoLogin({ commit }) {
      try {
        const cache_user_token = Cookies.get('current_user_token');
        if (!this.getters.isLogedIn && cache_user_token) {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${cache_user_token}`;
          const decode_data = VueJwtDecode.decode(cache_user_token);
          console.log(decode_data);
          commit('SET_CURRENT_USER', {
            user: decode_data.user,
            token: cache_user_token,
            defLang: decode_data.defLang,
            trLang: decode_data.trLang,
          });
        }
      } catch (e) {
        await this.dispatch('logout');
      }
    },
    async login({ commit }, payload) {
      const login_data = (await UserRepository.login(payload)).data;
      const decode_data = VueJwtDecode.decode(login_data.token);
      commit('SET_CURRENT_USER', {
        token: login_data.token,
        user: decode_data.user,
        defLang: decode_data.defLang,
        trLang: decode_data.trLang,
      });
    },
    async logout({ commit }) {
      Cookies.remove('current_user_token');
      commit('CLEAR_CURRENT_USER');
      window.location = '/pages/login';
    },
  },
};
