import Repository from '../axios';

export default {
  get() {
    return Repository.get('/classifier/type');
  },
  search(term) {
    return Repository.get(`/classifier/${term}`);
  },
  create(type, payload) {
    return Repository.post(`/classifier/${type}`, payload);
  },
  delete(id) {
    return Repository.delete(`/classifier/${id}`);
  },
  addTranslation(id, payload) {
    return Repository.post(`/classifier/${id}/translation`, payload);
  },
  getOne(id) {
    return Repository.get(`/classifier/${id}`);
  },
  update(id, payload) {
    return Repository.put(`/classifier/${id}`, payload);
  },
};
