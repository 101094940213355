import Repository from '../axios';
import { getUrlParamsFromObj } from '@/utils/string';

export default {
  get() {
    return Repository.get('/product/search/');
  },
  search(term, conf, params) {
    const config = { ...(conf || {}) };
    const { pharmacyStatus } = config;
    // rename api url param if needed
    if (Object.prototype.hasOwnProperty.call(config, 'pharmacyStatus')) {
      config.EPharmacyStatus = pharmacyStatus;
      delete config.pharmacyStatus;
    }

    const urlParamsStr = getUrlParamsFromObj(config);
    return Repository.get(`/product/search/${term}?${urlParamsStr}`, params);
  },
  details(id) {
    return Repository.get(`/product/${id}`);
  },
  update(id, payload) {
    return Repository.put(`/product/${id}`, payload);
  },
  parentMove(relation_id, payload) {
    return Repository.put(`/producttree/product/${relation_id}`, payload);
  },
  stockLevel(id) {
    return Repository.get(`/product/${id}/stock`);
  },
};
