import Repository from '../axios';

export default {
  get() {
    return Repository.get('/producttree');
  },
  delete(id) {
    return Repository.delete(`/producttree/${id}`);
  },
  update(id, payload) {
    return Repository.put(`/producttree/${id}`, payload);
  },
  getOne(id) {
    return Repository.get(`/producttree/${id}`);
  },
  childCreate(payload) {
    return Repository.post('/producttree', payload);
  },
  rootCreate(payload) {
    return Repository.post('/producttree', payload);
  },
  productByNode(id) {
    return Repository.get(`/producttree/${id}/product`);
  },
  deleteProductFromNode(relationId) {
    return Repository.delete(`/producttree/product/${relationId}`);
  },
  addProductToTree(payload) {
    return Repository.post('/producttree/product', payload);
  },
  getParents(id) {
    return Repository.get(`/producttree/${id}/validparent`);
  },
  moveToParent(id, payload) {
    return Repository.put(`/producttree/${id}/product`, payload);
  },
  moveToGroup(relationId, payload) {
    return Repository.put(`/producttree/${relationId}`, payload);
  },
};
