import axios from '@/axios';
import store from '../store/store.js';

export default function setup() {
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    async function(error) {
      if (error.response.status === 400) {
        console.log('token expire');
        await store.dispatch('logout');
      }
      throw new Error('Error from interceptor');
    },
  );
}
